
import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Flex, WhiteSpace, List } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import './applyList.css';
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import {
    transDate,
    onWrapTouchStart
} from "../../js/utils.js";
import {
    autoLogin,
    getApplyList,
    updateUserStatus
} from "../../js/request.js";



const statusCode = [
    {code:1,text:"申请中",color:"#ccc"},
    {code:2,text:"驳回",color:"red"},
    {code:3,text:"报名通过",color:"green"},
    {code:4,text:"可预约",color:"green"},
    {code:5,text:"可预约并信任",color:"green"}
]

const getStatus = (code,selfCode)=>{

    if(selfCode&&code==4){
        code = 5;
    }
    let status = statusCode.filter(s=>s.code==code)[0]
    return <div style={{color:status.color}}>{status.text}</div>
}

class ApplyList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMsg: false,
            applyList: [],
            userList: [],
            refreshing: true,
            isLoading: false,
            useBodyScroll: true,
            groupId: 0
        };
    }

    componentDidMount() {
        autoLogin("",userInfo=>{
            getApplyList().then(res => {
                if (res) {
                    console.log(res)
                    this.setState({ userList: res.list })
                }
            })
        });
    }


    handleClick = (groupId) => {
        //e.preventDefault();
        this.setState({
            showMsg: true,
            groupId
        })
    }

    onClose = () => {
        this.setState({
            showMsg: false
        })
    }

    update = (statusCode,selfCode) => {
        updateUserStatus({
            id: this.state.groupId,
            statusCode,
            selfCode
        }).then(res => {
            this.componentDidMount();
        })
        this.onClose();
    }


    render() {
        const { userList, groupId } = this.state;
        const user = userList && groupId ? userList.filter(u => u.groupId == groupId)[0] : null

        return < div >
            <List renderHeader={() => '报名审批'}>
                {
                    userList.map(row => <List.Item key={row.groupId} arrow="horizontal" onClick={(e) => this.handleClick(row.groupId)} extra={getStatus(row.statusCode,row.selfCode)}>
                        {row.userName}
                    </List.Item>)
                }
            </List>
            <Modal
                visible={this.state.showMsg}
                transparent
                closable
                onClose={this.onClose}
                title="报名信息"
                footer={[{ text: '重置', onPress: () => { this.update(1,false); } },{ text: '拒绝', onPress: () => { this.update(2,false); } }, { text: '仅同意', onPress: () => { this.update(3,false); } },{ text: '同意且可预约', onPress: () => { this.update(4,false); } },{ text: '同意预约且能自主开锁', onPress: () => { this.update(4,true); } }]}
                wrapProps={{ onTouchStart: onWrapTouchStart }}
                afterClose={() => { }}
            >
                {
                    user ? <div style={{ width: "100%", height: "100%" }}>
                        <Flex>
                            <Flex.Item className={"lable"}>姓名:</Flex.Item>
                            <Flex.Item className="name">{user.userName}</Flex.Item>
                        </Flex>
                        <Flex>
                            <Flex.Item className={"lable"}>同组人:</Flex.Item>
                            <Flex.Item className="name">{user.studentList.map(u => u.userName + " ")}</Flex.Item>
                        </Flex>
                        <Flex>
                            <Flex.Item className={"lable"}>选择课题:</Flex.Item>
                            <Flex.Item className="name">{user.courseName}</Flex.Item>
                        </Flex>
                        <Flex>
                            <Flex.Item className={"lable"}>班级:</Flex.Item>
                            <Flex.Item className="name">{user.className}</Flex.Item>
                        </Flex>
                        <Flex>
                            <Flex.Item className={"lable"}>报名时间:</Flex.Item>
                            <Flex.Item className="name">{transDate(user.registerTime)}</Flex.Item>
                        </Flex>
                    </div> : null
                }
            </Modal>
        </div >
    }
}

export default ApplyList;