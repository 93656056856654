
import React from 'react';
import { WhiteSpace, NavBar, List, Button } from 'antd-mobile';
import copy from 'copy-to-clipboard';
import 'antd-mobile/dist/antd-mobile.css';

import {
  getAdminLab,
  getPeriod,
  addPeriod,
  updatePeriod,
  autoLogin
} from "../../js/request.js";

import {
  showModal,
  showOkModal
} from "../../js/utils.js";

class Period extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      list: [],
      showDetail: false,
      showList: false
    }
  }

  componentDidMount() {
    autoLogin("", async (userInfo) => {
      const lablist = await getAdminLab();
      if (lablist) {
        var list = lablist.list;
        this.setState({
          list: list
        })
        if (list.length == 0) {
          showModal("", "没有您管理的实验室");
        } else if (list.length == 1) {
          this.setState({
            labName:list[0].labName,
            labId: list[0].labId
          })
          getPeriod(list[0].labId).then(data => {
            this.setState({
              showDetail: true
            })
            this.setPeriod(data)
          })
        }else{
          this.setState({
            showList: true
          })
        }
      }
    })
  }

  add = () => {
    showOkModal("","确定开启下一个研学周期？",()=>{
      addPeriod(this.state.labId).then(this.setPeriod)
    })
  }

  update = () => {

    let status = this.state.status == 1 ? 0 : 1;
    showOkModal("",status==0?"确定关闭研学周期？":"确定开启研学周期？",()=>{
      updatePeriod(this.state.labId, status).then(this.setPeriod)
    })
    
  }

  getDetail = (labId) => {
    const labName = this.state.list.find( (l)=> l.labId==labId ).labName;
    this.setState({
      labId: labId,
      labName
    })
    getPeriod(labId).then(data => {
      this.setState({
        showDetail: true
      })
      this.setPeriod(data)
    })
  }

  setPeriod = (data) => {
    if (data) {
      this.setState({
        period: data.period,
        status: data.status
      })
    }
  }


  render() {

    const { labName, showDetail, showList, list } = this.state;

    return showDetail ? <div>
      {
        list.length > 1 ? <NavBar
          mode="light"
          leftContent="返回"
          onLeftClick={(e) => { this.setState({ showDetail: false }) }}
        >{labName}</NavBar> : null
      }
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      {
        this.state.period ? <h1>当前研学周期:{this.state.period}</h1> : null
      }
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      {
        this.state.period ? <h2>{this.state.status == 1 ? "已开启" : "已关闭"}</h2> : null
      }
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      {
        this.state.period ? <Button type="primary" onClick={this.update}>{this.state.status ? "关闭当前研学周期" : "开启当前研学周期"}</Button> : null
      }
      <WhiteSpace size="lg" />
      <Button type="primary" onClick={this.add}>开启下一研学周期</Button>

    </div > : showList ? <List renderHeader={() => '选择实验室'}>
      {
        list.map(row => <List.Item key={row.labId} arrow="horizontal" onClick={(e) => this.getDetail(row.labId)}>{row.labName}</List.Item>)
      }
    </List> : null
  }
}

export default Period;