
import React from 'react';
import ReactDOM from 'react-dom';
import { Flex, WhiteSpace, Accordion,List } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import {
    PlusCircleOutlined
} from '@ant-design/icons';

import {
    autoLogin,
    getCourseList,
    getLabList,
    getPeriod
} from "../../js/request.js";


const NUM_ROWS = 20;
const statusCode = [
    { code: 3, text: "历史", color: "#ccc" },
    { code: 2, text: "关闭", color: "red" },
    { code: 1, text: "开放", color: "green" }
]
const getStatus = (code) => {

    let status = statusCode.filter(s => s.code == code)[0]
    return <div style={{ color: status.color }}>{status.text}</div>
}
class CourseList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            total: NUM_ROWS,
            curPeriod:true,
            period:0,
            list: [],
            labList:[],
            refreshing: true,
            isLoading: false,
            useBodyScroll: true,
            activeKey: []
        };
        //this.toEdit.bind(this);

    }

    componentDidMount() {
        autoLogin(null, async (userInfo) => {
            const lablist = await getLabList(1, 10000);
            if (lablist) {
                var list = lablist.list.filter(lab => lab.status == 1);
                this.setState({
                    labList: list
                })
            }
            this.onEndReached();
        });

    }

    componentDidUpdate() {
        if (this.state.useBodyScroll) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }

    onEndReached = (event) => {

        this.setState({ isLoading: true });
        getCourseList().then(data => {
            console.log(data)
            const list = this.state.list.concat(data.list);
            this.setState({
                total: data.total,
                list,
                isLoading: false,
            })
        })
    };

    handleClick = () => {
        this.inputRef.focus();
    }

    toEdit = (id) => {
        this.props.history.push("/course/edit/" + id);
    }

    toAdd = (e) => {
        this.props.history.push("/course/add");
    }

    panelChange = (key) => {
        this.setState({
            activeKey: key
        });
    }

    render() {

        const {labList,list,activeKey} = this.state;
        return < div >
            <List renderHeader={() => '课题管理'}>
                <List.Item key="0" onClick={(e) => this.toAdd(e)}>
                    <Flex justify="between">
                        <div>添加课题</div>
                        <PlusCircleOutlined />
                    </Flex>
                </List.Item>
            </List>
            <WhiteSpace size="lg" />
            <Accordion activeKey={activeKey} style={{}} onChange={this.panelChange}>
                {
                    labList.map(lab=><Accordion.Panel key={lab.labId} header={lab.labName}>
                    <List>
                        {
                            list.filter(row=>row.labId==lab.labId).map(row => <List.Item key={row.id} arrow="horizontal" extra={getStatus(row.status)} onClick={(e) => this.toEdit(row.courseId)}>{row.courseCode + "-" + row.courseName}</List.Item>)
                        }
                    </List>
                </Accordion.Panel>)
                }
            </Accordion>
        </div >
    }
}

export default CourseList;