
import React from 'react';
import {
  autoLogin
} from "../js/request.js";
class Login extends React.Component {


    constructor(props){
      super(props)

      var state=123;
      state = autoLogin(state);

    }
    render() {
      console.log(this.props.match)
      return <h1>物理研学之窗</h1>;
    }
}

export default Login;