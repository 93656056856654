
import React from 'react';
import { Flex, Card, List, WhiteSpace, NavBar, Switch } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import './adminLab.css';
import {
    PlusCircleOutlined
} from '@ant-design/icons';
import {
    showModal
} from "../../js/utils.js";
import {
    autoLogin,
    getAdminLab,
    updateLabState,
    getLabPartList,
    getLabDetail
} from "../../js/request.js";


const statusCode = [
    {code:0,text:"关闭",color:"red"},
    {code:1,text:"开放",color:"green"}
]
const getStatus = (code)=>{

    let status = statusCode.filter(s=>s.code==code)[0]
    return <div style={{color:status.color}}>{status.text}</div>
}

class AdminLab extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            list: [],
            partList: [],
            showDetail: false,
            showList: false
        }
    }

    componentDidMount() {

        autoLogin(null, async (userInfo) => {
            const lablist = await getAdminLab();
            if (lablist) {
                var list = lablist.list;
                this.setState({
                    list: list
                })
                if (list.length == 0) {
                    showModal("", "没有您管理的实验室");
                } else if (list.length == 1) {
                    getLabDetail(list[0].labId).then(data => {
                        if (data) {
                            this.setState({
                                labId:list[0].labId,
                                detail: data,
                                showDetail: true
                            })
                        }
                    })
                    getLabPartList(list[0].labId).then(data => {
                        if (data) {
                            this.setState({
                                partList: data.list
                            })
                        }
                    })
                } else {
                    this.setState({
                        showList: true
                    })
                }
            }
        });
    }
    handleClick = () => {
        this.inputRef.focus();
    }

    toEdit = (labId, partId) => {
        this.props.history.push("/part/edit/" + labId + "/" + partId);
    }

    toAdd = (labId) => {
        this.props.history.push("/part/add/" + labId);
    }

    getDetail = (labId) => {
        getLabDetail(labId).then(data => {
            if (data) {
                this.setState({
                    labId,
                    detail: data,
                    showDetail: true
                })
            }
        })
        getLabPartList(labId).then(data => {
            if (data) {
                this.setState({
                    partList: data.list
                })
            }
        })
    }

    updateState = (checked) => {
        var { detail } = this.state
        detail.status = checked ? 1 : 0
        updateLabState(detail).then(status => {
            detail.status = status
            this.setState({
                detail
            })
        })

    }

    render() {

        const { detail, showDetail, showList, list } = this.state;
        const Header = (props) => <div className="header">{props.title}</div>

        return showDetail ? <div>

            <Card full>
                {
                    list.length > 1 ? <NavBar
                        mode="light"
                        leftContent="返回"
                        onLeftClick={(e) => { this.setState({ showDetail: false }) }}
                    >{detail.labName}</NavBar> : <Card.Header title={<Header title={detail.labName} />} />
                }
                <Card.Body>
                    <Flex>
                        <Flex.Item className={"lable"}>管理员:</Flex.Item>
                        <Flex.Item className="name">{detail.administratorName}</Flex.Item>
                    </Flex>
                    <Flex>
                        <Flex.Item className={"lable"}>门锁编号:</Flex.Item>
                        <Flex.Item className="name">{detail.lockNum}</Flex.Item>
                    </Flex>
                    <Flex>
                        <Flex.Item className={"lable"}>门锁密码:</Flex.Item>
                        <Flex.Item className="name">{detail.lockPassword}</Flex.Item>
                    </Flex>
                    <Flex>
                        <Flex.Item className={"lable"}>学校:</Flex.Item>
                        <Flex.Item className="name">{detail.address}</Flex.Item>
                    </Flex>
                </Card.Body>
            </Card>
            <WhiteSpace />
            <List.Item
                extra={<Switch checked={detail.status == 1} onChange={(checked) => this.updateState(checked)} />}
            >开放状态</List.Item>
            <WhiteSpace />
            <List renderHeader={() => '分区管理'}>
                <List.Item key="0" onClick={(e) => this.toAdd(this.state.labId)}>
                    <Flex justify="between">
                        <div>添加分区</div>
                        <PlusCircleOutlined />
                    </Flex>
                </List.Item>
                {
                    this.state.partList.map(row => <List.Item key={row.partId} arrow="horizontal" onClick={(e) => this.toEdit(row.labId, row.partId)}>{row.partCode}:{row.courseName}</List.Item>)
                }
            </List>
        </div> : showList ? <List renderHeader={() => '选择实验室'}>
            {
                list.map(row => <List.Item key={row.labId} arrow="horizontal" extra={getStatus(row.status)} onClick={(e) => this.getDetail(row.labId)}>{row.labName}</List.Item>)
            }
        </List> : null
    }
}

export default AdminLab;