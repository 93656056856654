
import React from 'react';
import { Accordion, NavBar, SegmentedControl, WhiteSpace, List } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import {
    autoLogin,
    getLabList,
    getLabAllCourseList,
    getPeriod
} from "../../js/request.js";


const NUM_ROWS = 20;

const statusCode = [
    { code: 3, text: "历史", color: "#ccc" },
    { code: 2, text: "关闭", color: "red" },
    { code: 1, text: "开放", color: "green" }
]
const getStatus = (code) => {

    let status = statusCode.filter(s => s.code == code)[0]
    return <div style={{ color: status.color }}>{status.text}</div>
}
class CourseShow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            curPeriod:true,
            period:0,
            list: [],
            courseList: [],
            showDetail: false,
            showList: false,
            activeKey: []
        };
        //this.toEdit.bind(this);

    }

    componentDidMount() {
        autoLogin(null, async (userInfo) => {
            const lablist = await getLabList(1, 10000);
            if (lablist) {
                var list = lablist.list.filter(lab => lab.status == 1);
                this.setState({
                    list: list
                })
            }
        });
    }

    componentDidUpdate() {

    }

    getDetail = (labId) => {
        const labName = this.state.list.find((l) => l.labId == labId).labName;
        this.setState({
            labId: labId,
            labName,
            showDetail: true,
            curPeriod:true,
        })
        getPeriod(labId).then(data => {
            this.setState({
                period:data.period
            })
        })
        getLabAllCourseList(labId).then(data => {
            this.setCourseList(data)
        })
    }

    setCourseList = (data) => {
        if (data) {
            this.setState({
                courseList : data.list.filter(c => c.status != 0)
            })
        }
    }

    panelChange = (key) => {
        this.setState({
            activeKey: key
        });
    }

    onChange = (e) => {
        this.setState({
            curPeriod: (e.nativeEvent.selectedSegmentIndex==0)
        })
    }

    render() {

        const { curPeriod,labName, showDetail, list, courseList,period } = this.state;

        const renderList = (list) => {
            if(curPeriod){
                list = list.filter(c=>c.period == period)
            }
            // let newList = list.reduce((cur, next) => {
            //     // eslint-disable-next-line no-unused-expressions
            //     obj[next.courseId] ? "" : obj[next.courseId] = true && cur.push(next);
            //     return cur;
            // }, [])
            list.map(c=>{
                if(c.period!=period){
                    c.status=3;
                }
                return c;
            })
            return <List>
                {list.map(c => <List.Item extra={getStatus(c.status)}>{c.courseCode}:{c.courseName}</List.Item>)}
            </List>
        }
        return showDetail ? < div >
            <NavBar
                mode="light"
                leftContent="返回"
                onLeftClick={(e) => { this.setState({ showDetail: false }) }}
            >{labName}</NavBar>
            <WhiteSpace size="lg" />
            <SegmentedControl
                values={['当前研学周期', '全部课题']}
                selectedIndex={curPeriod?0:1}
                style={{ height: '40px' }}
                onChange={this.onChange}
            />
            <WhiteSpace size="lg" />
            <Accordion activeKey={this.state.activeKey} style={{}} onChange={this.panelChange}>
                <Accordion.Panel key="0" header="自拟课题">
                    {renderList(courseList.filter(c => c.courseType == 0))}
                </Accordion.Panel>
                <Accordion.Panel key="1" header="发散性课题">
                    {renderList(courseList.filter(c => c.courseType == 1))}
                </Accordion.Panel>
                <Accordion.Panel key="2" header="收敛性课题">
                    {renderList(courseList.filter(c => c.courseType == 2))}
                </Accordion.Panel>
            </Accordion>

        </div > : <List renderHeader={() => '选择实验室'}>
                {
                    list.map(row => <List.Item key={row.labId} arrow="horizontal" extra={row.address} onClick={(e) => this.getDetail(row.labId)}>{row.labName}</List.Item>)
                }
            </List>
    }
}

export default CourseShow;