
import React from 'react';
import { WhiteSpace, Button } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';

import {
  updateMpStatus,
  getMpStatus,
  autoLogin
} from "../js/request.js";

import {
  showOkModal
} from "../js/utils.js";

class MpAdmin extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      status: 0,
      show: false
    }
  }

  componentDidMount() {
    autoLogin("", (userInfo) => {
      getMpStatus().then(this.setStatus)
    })
  }

  update = () => {

    let status = this.state.status == 1 ? 0 : 1;
    showOkModal("",status==0?"确定关闭公众号？":"确定开启公众号？",()=>{
      updateMpStatus(status).then(this.setStatus)
    })
  }


  setStatus = (data) => {
    if (data!=null) {
      this.setState({
        show: true,
        status: data
      })
    }
  }


  render() {

    const { status, show } = this.state;
    return show ? <div>
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <h2>公众号状态</h2>
      <h2>{status == 1 ? "已开启" : "已关闭"}</h2>
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <Button type="primary" onClick={this.update}>{status == 1 ? "关闭公众号" : "开启公众号"}</Button>
      <WhiteSpace size="lg" />
    </div > : null
  }
}

export default MpAdmin;