
import React from 'react';
import { List } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';


class SimpleList extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }
    
    render() {
        const title = this.props.title;
        const list = this.props.list;
        const itemClick = this.props.itemClick;
        return < div >
            <List renderHeader={() => title}>
                {
                    list.map(row => <List.Item key={row.id} arrow="horizontal" extra={row.extra} onClick={(e) => itemClick(row.id)} >
                        {row.name}
                    </List.Item>)
                }
            </List>
        </div >
    }
}

export default SimpleList;