import axios from 'axios';
import { Toast } from 'antd-mobile';
import {
    domain,
    getWxConfigUrl,

    loginUrl,
    registerUrl,

    getMpStatusUrl,
    updateMpStatusUrl,

    getLabListUrl,
    getLabDetailUrl,
    addLabUrl,
    updateLabUrl,
    updateLabStateUrl,
    getAdminLabUrl,
    getPeriodUrl,
    addPeriodUrl,
    updatePeriodUrl,

    getLabPartListUrl,
    getPartDetailUrl,
    addPartUrl,
    updatePartUrl,

    getLabAdminListUrl,
    addLabUserUrl,
    updateLabUserUrl,
    getApplyListUrl,
    getAllApplyListUrl,
    getApplyMsgUrl,
    getApplyMsgByLabUrl,
    updateUserStatusUrl,
    getLockRecordUrl,

    getCourseListUrl,
    getLabCourseListUrl,
    getLabAllCourseListUrl,
    getCourseDetailUrl,
    addCourseUrl,
    updateCourseUrl,
    deleteCourseUrl,

    getReservePartListUrl,
    getReserveInfoUrl,
    reserveUrl,
    getReserveHistoryUrl,
    getEntryLabListUrl,
    getLabPasswordUrl,

    getRegisterCodeUrl,

    getNewsListUrl,

} from "../js/urlList.js";

import {
    getSearchParam
} from "./utils.js";

import {
    handlerData
} from "./requestDataHandler.js";

// 请求拦截器
export const fetch = axios.create({
    baseURL: domain,
    timeout: 20000,
    withCredentials: true,
    headers: {
        'Content-type': "application/json;charset=UTF-8"
    }
});

fetch.interceptors.request.use((config) => {
    if (config.method == "post" && config.data == undefined) {
        config.data = "{}"
    }
    var token = localStorage.getItem("Authorization");
    if (token) {
        config.headers.common["Authorization"] = token;
    }
    return config;
}, erro => Promise.reject(erro));
// 响应拦截器
fetch.interceptors.response.use((response) => {
    const { status, data } = response;
    // 只返回code为200时的数据，其他状态不返回
    if (status === 200 && data.code === 200) {
        Toast.hide();
        return data.data;
    } else {
        console.log(response);
        handlerData(status,data);
        //return Promise.reject(new Error(data.msg));
    }
}, (error) => {
    console.log(error);
    const res = error.response;
    if(res){
        const { status, data } = res;
        handlerData(status,data);
        //return Promise.reject(new Error(data.msg));
    }
    //throw error;
});

export const getWxConfig = data => fetch({
    url: getWxConfigUrl.url,
    method: getWxConfigUrl.method,
    data
})
const mpLogin = data => fetch({
    url: loginUrl.url,
    method: loginUrl.method,
    data,
})
export const register = data =>fetch({
    url: registerUrl.url,
    method: registerUrl.method,
    data,
}).then(data=>{
    console.log(data)
    localStorage.Authorization = data.token;
    localStorage.setItem("userInfo", window.JSON.stringify(data));
    localStorage.setItem("loginTime",new Date().getTime());
}).catch(e=>{
    console.log(e);
})

export const autoLogin = (state,callback) => {

    var now = new Date().getTime();

    var token = localStorage.getItem("Authorization");
    var loginTime = localStorage.getItem("loginTime");


    if(token&&loginTime&&parseInt(loginTime)>(now-600000)){
        if(callback){
            var userInfoStr = localStorage.getItem("userInfo");
            callback(window.JSON.parse(userInfoStr));
            console.log(callback)
        }
        return state;
    }
    var url = window.location.href;

    if(url.search("localhost")!=-1){
        mpLogin({
            code: "123456"
        }).then((data) => {
            console.log(data)
            localStorage.Authorization = data.token;
            localStorage.setItem("userInfo", window.JSON.stringify(data));
            localStorage.setItem("loginTime",now);
            if(callback){
                callback(data);
            }
        }).catch((e) => {

            console.log(e);
        });
        return state;
    }

    var param = getSearchParam(url);

    if (!param.code) {
        if (state) {
            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5d762847c5f97d07&redirect_uri=" + encodeURIComponent(url) + "&response_type=code&scope=snsapi_userinfo&state=" + state + "#wechat_redirect"
        } else {
            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5d762847c5f97d07&redirect_uri=" + encodeURIComponent(url) + "&response_type=code&scope=snsapi_userinfo#wechat_redirect"
        }
    } else {
        mpLogin({
            code: param.code
        }).then((data) => {
            localStorage.Authorization = data.token;
            localStorage.setItem("userInfo", window.JSON.stringify(data));
            localStorage.setItem("loginTime",now);
            if(callback){
                callback(data);
            }
        }).catch(() => {
            
        });
        return param.state;
    }
}

//获取公众号状态
export const getMpStatus = () =>fetch({
    url: getMpStatusUrl.url,
    method: getMpStatusUrl.method,
})
//更新公众号状态
export const updateMpStatus = (status) =>fetch({
    url: updateMpStatusUrl.url+status,
    method: updateMpStatusUrl.method,
})

//获取实验室列表
export const getLabList = (page,size) => fetch({
    url: getLabListUrl.url+"?page="+page+"&size="+size,
    method: getLabListUrl.method,
})
//获取
export const getLabDetail = (id) => fetch({
    url: getLabDetailUrl.url+id,
    method: getLabDetailUrl.method,
})
//添加
export const addLab = (data) => fetch({
    url: addLabUrl.url,
    method: addLabUrl.method,
    data
})
//更新
export const updateLab = (data) => fetch({
    url: updateLabUrl.url,
    method: updateLabUrl.method,
    data
})
//更新状态
export const updateLabState = (data) => fetch({
    url: updateLabStateUrl.url,
    method: updateLabStateUrl.method,
    data
})
//获取自己管理的实验室
export const getAdminLab = () => fetch({
    url: getAdminLabUrl.url,
    method: getAdminLabUrl.method
})

export const getLockRecord = () => fetch({
    url: getLockRecordUrl.url,
    method: getLockRecordUrl.method
})

export const getLabPartList = (labId) => fetch({
    url: getLabPartListUrl.url+labId,
    method: getLabPartListUrl.method
})

//研学周期
export const getPeriod = (labId) => fetch({
    url: getPeriodUrl.url+labId,
    method: getPeriodUrl.method
})
export const addPeriod = (labId) => fetch({
    url: addPeriodUrl.url+labId,
    method: addPeriodUrl.method
})
export const updatePeriod = (labId,status) => fetch({
    url: updatePeriodUrl.url+labId+"/"+status,
    method: updatePeriodUrl.method
})

export const getPartDetail = (partId) => fetch({
    url: getPartDetailUrl.url+partId,
    method: getPartDetailUrl.method
})

//添加
export const addPart = (data) => fetch({
    url: addPartUrl.url,
    method: addPartUrl.method,
    data
})
//更新
export const updatePart = (data) => fetch({
    url: updatePartUrl.url,
    method: updatePartUrl.method,
    data
})


/********用户*************/
export const getLabAdminList = () => fetch({
    url: getLabAdminListUrl.url,
    method: getLabAdminListUrl.method
})
export const addLabUser = (data) => fetch({
    url: addLabUserUrl.url,
    method: addLabUserUrl.method,
    data
})
export const updateLabUser = (data) => fetch({
    url: updateLabUserUrl.url,
    method: updateLabUserUrl.method,
    data
})

export const updateUserStatus = (data)=>fetch({
    url: updateUserStatusUrl.url,
    method: updateUserStatusUrl.method,
    data
})

/******课程管理********/
//获取实验室课程列表
export const getLabCourseList = (labId) => fetch({
    url: getLabCourseListUrl.url+"?labId="+labId,
    method: getLabCourseListUrl.method,
})

export const getLabAllCourseList = (labId) => fetch({
    url: getLabAllCourseListUrl.url+labId,
    method: getLabAllCourseListUrl.method,
})
//获取课程列表
export const getCourseList = () => fetch({
    url: getCourseListUrl.url,
    method: getCourseListUrl.method,
})
//获取
export const getCourseDetail = (id) => fetch({
    url: getCourseDetailUrl.url+id,
    method: getCourseDetailUrl.method,
})
//添加
export const addCourse = (data) => fetch({
    url: addCourseUrl.url,
    method: addCourseUrl.method,
    data
})
//更新
export const updateCourse = (data) => fetch({
    url: updateCourseUrl.url,
    method: updateCourseUrl.method,
    data
})
//删除
export const deleteCourse = (id)=> fetch({
    url: deleteCourseUrl.url+id,
    method: deleteCourseUrl.method
})

export const getApplyList = () => fetch({
    url: getApplyListUrl.url,
    method: getApplyListUrl.method,
})

export const getAllApplyList = () => fetch({
    url: getAllApplyListUrl.url,
    method: getAllApplyListUrl.method,
})
export const getApplyMsg = (groupId) => fetch({
    url: getApplyMsgUrl.url+groupId,
    method: getApplyMsgUrl.method,
})
export const getApplyMsgByLab = (labId) => fetch({
    url: getApplyMsgByLabUrl.url+labId,
    method: getApplyMsgByLabUrl.method,
})

export const getReservePartList = (labId) => fetch({
    url: getReservePartListUrl.url+labId,
    method: getReservePartListUrl.method,
})

export const reserve = (data)  => fetch({
    url: reserveUrl.url,
    method: reserveUrl.method,
    data
})

//自己的预约信息
export const getReserveInfo = (labId)  => fetch({
    url: getReserveInfoUrl.url+labId,
    method: getReserveInfoUrl.method
})
export const getReserveHistory = (groupId)  => fetch({
    url: getReserveHistoryUrl.url+groupId,
    method: getReserveHistoryUrl.method
})

//可以进入的实验室列表
export const getEntryLabList =() => fetch({
    url: getEntryLabListUrl.url,
    method: getEntryLabListUrl.method,
})

//获取临时密码
export const getLabPassword = (labId) => fetch({
    url: getLabPasswordUrl.url+labId,
    method: getLabPasswordUrl.method,
})

export const registerCode = () => fetch({
    url: getRegisterCodeUrl.url,
    method: getRegisterCodeUrl.method,
})

export const getNewsList = () => fetch({
    url: getNewsListUrl.url,
    method: getNewsListUrl.method
})