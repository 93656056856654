import React from 'react';
import { Flex, WhiteSpace, Calendar, DatePicker, List, Button, Modal, Picker, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import 'antd-mobile/dist/antd-mobile.css';
import {
    dateFormat,
    showFormValidatorErr,
    showToast,
    showModal,
    onWrapTouchStart,
    transDate,
    showOkModal
} from "../../js/utils.js";
import {
    autoLogin,
    getApplyMsgByLab,
    getReservePartList,
    getPartDetail,
    getReserveInfo,
    reserve
} from "../../js/request.js";



const now = new Date();
const startDate = new Date(now.toLocaleDateString());

class ReserveForm extends React.Component {

    constructor(props) {
        super(props);

        var labId = this.props.match.params.labId;
        this.state = {
            showInfo: false,
            info: null,
            showForm: false,
            showReserve: false,
            labId,
            partId: 0,
            extra: {},
            calendarShow: false,
            groupNum: "",
            courseName: "",
            partList: [],
            date: null,
            enableDates: [],
            reserveGroups: [],
            reserveTime: [],
            startMinTime: new Date(),
            startMaxTime: new Date(),
            endMinTime: new Date(),
            endMaxTime: new Date()
        };
    }

    async componentDidMount() {
        autoLogin("", (userInfo) => {
            getApplyMsgByLab(this.state.labId).then(data => {
                if (data && data.statusCode == 4) {
                    this.setState({
                        courseName: data.courseName,
                        groupNum: data.groupNum,
                        groupId: data.groupId
                    })
                    getReserveInfo(this.state.labId).then(data => {
                        if (data) {
                            this.setState({
                                showForm: false,
                                showInfo: true,
                                info: data
                            })
                        } else {
                            this.setState({
                                showInfo: false,
                                showForm: true,
                            })
                        }
                    })

                    getReservePartList(this.state.labId).then((data) => {
                        console.log(data);
                        if (data) {
                            let partList = data.list.filter(p => p.isOpen).map(p => {
                                return { value: p.partId, label: p.partCode }
                            })
                            this.setState({
                                partList
                            })
                        }
                    });
                } else {
                    if (data) {
                        if (data.statusCode == 1 || data.statusCode == 2) {
                            showModal("", "您的报名信息还未通过，不能进行预约");
                        } else {
                            showModal("", "您的预约权限未开通，请先联系管理员开通");
                        }
                    } else {
                        showModal("", "您还未报名该实验室课题，不能进行预约");
                    }
                }
            })
        });
    }

    onCancel = () => {
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            calendarShow: false
        });
    }

    onSelectHasDisableDate = (dates) => {
        console.warn('onSelectHasDisableDate', dates);
    }


    onConfirm = (date) => {
        let selected = this.state.enableDates.filter(d => d.reserveDate == date.getTime())[0]
        console.log(selected)
        let reserveGroups = selected.reserveGroups.filter(g => g.groupId != this.state.groupId)

        let { startMinTime, startMaxTime, endMinTime, endMaxTime } = this.state;
        startMinTime.setHours(8)
        startMinTime.setMinutes(0)
        endMinTime.setHours(8)
        endMinTime.setMinutes(10)
        startMaxTime.setHours(22)
        startMaxTime.setMinutes(0)
        endMaxTime.setHours(22)
        endMaxTime.setMinutes(10)
        this.setDateForTime(startMinTime, date)
        this.setDateForTime(startMaxTime, date)
        this.setDateForTime(endMinTime, date)
        this.setDateForTime(endMaxTime, date)
        this.setState({
            date: date,
            calendarShow: false,
            reserveGroups,
            startMinTime,
            startMaxTime,
            endMinTime,
            endMaxTime
        })
    }

    setDateForTime = (time, date) => {
        time.setFullYear(date.getFullYear())
        time.setMonth(date.getMonth())
        time.setDate(date.getDate())
        time.setSeconds(0);
        time.setMilliseconds(0);
    }

    partChange = (val) => {
        if (val) {
            this.setState({ partId: val[0], extra: {} })
            Toast.loading('查询可预约日期', 5, () => {
                console.log('请求超时 !!!');
            });
            getPartDetail(val[0]).then(res => {
                Toast.hide();
                if (res) {
                    console.log(res)
                    var { extra } = this.state
                    res.enableDates.forEach(date => {
                        extra[+date.reserveDate] = { info: "可选", disable: false }
                    })
                    this.setState({
                        extra,
                        enableDates: res.enableDates,
                        date: null
                    })
                }
            })

        }
    }

    showReserveDetail() {
        this.setState({
            showReserve: true
        })
    }

    onClose = () => {
        this.setState({
            showReserve: false
        })
    }

    getDateExtra = date => {
        let ext = this.state.extra[+date];
        if (ext && date.getTime() >= startDate.getTime()) {
            return ext;
        } else {
            return { info: "", disable: true }
        }
    }

    reReserve = () => {
        showOkModal("", "重新预约将会清除现在的预约记录，是否继续？", () => {
            this.setState({
                showInfo: false,
                showForm: true,
                date: null,
            })
        })
    }

    submit = () => {

        this.props.form.validateFields((error, values) => {
            if (this.state.date == null) {
                showToast("请选择预约日期")
                return;
            }
            if (error) {
                showFormValidatorErr(error);
                return;
            }
            let partId = values.part[0]

            let date = this.state.date
            let startTime = values.startTime
            let endTime = values.endTime
            this.setDateForTime(startTime, date)
            this.setDateForTime(endTime, date)

            if (endTime.getTime() - startTime.getTime() > (1000 * 60 * 30 + 500)) {
                showToast("预约时长不能超过30分钟")
                return;
            }

            if (endTime.getTime() - startTime.getTime() < 1000 * 60 * 10) {
                showToast("预约时长最短10分钟")
                return;
            }

            if (startTime.getTime() < (new Date()).getTime()) {
                showToast("预约时间不能早于现在")
                return;
            }

            if (!this.validateTime(startTime, endTime)) {
                showToast("预约时间与其他人冲突，可查看所选日期预约情况后重新选择")
                return;
            }
            let form = {
                ...values,
                partId,
                labId: parseInt(this.state.labId),
                date,
                startTime,
                endTime
            }
            console.log(form)
            Toast.loading('预约中', 5, () => {
                console.log('请求超时 !!!');
            });
            reserve(form).then(res => {
                this.componentDidMount();
            })

        })
    }

    validateTime(startTime, endTime) {
        // console.log(dateFormat("YYYY-mm-dd HH:MM:SS",startTime))
        // console.log(dateFormat("YYYY-mm-dd HH:MM:SS",endTime))
        const { reserveGroups } = this.state;
        let length = reserveGroups.length;
        if (length == 0) {
            return true;
        }

        let minTime = reserveGroups[0].startTime;
        let maxTime = reserveGroups[length - 1].endTime;

        if (endTime.getTime() <= minTime) {
            //插入开头
            return true;
        } else if (startTime.getTime() > maxTime) {
            //插入结尾
            return true;
        } else if (length > 1) {
            //插入中间
            for (let index = 1; index < length; index++) {
                let s = reserveGroups[index - 1].endTime;
                let e = reserveGroups[index].startTime;
                if (startTime.getTime() >= s && endTime.getTime() <= e) {
                    return true;
                }
            }
        }
        return false;
    }

    // validateDatePicker = (rule, date, callback) => {
    //     if (date && date.getMinutes() !== 15) {
    //         callback();
    //     } else {
    //         callback(new Error('15 is invalid'));
    //     }
    // }

    startTimeChange = (startTime) => {
        const { endMinTime, endMaxTime } = this.state;
        endMinTime.setHours(startTime.getHours());
        endMinTime.setMinutes(startTime.getMinutes());
        this.setState({
            endMinTime,
            endMaxTime
        })
        console.log(endMinTime)
    }

    endTimeChange = (endTime) => {
        const { startMinTime, startMaxTime } = this.state;
        startMaxTime.setHours(endTime.getHours());
        startMaxTime.setMinutes(endTime.getMinutes());
        this.setState({
            startMinTime,
            startMaxTime
        })
    }

    render() {

        const { showInfo, info, showForm, date, partList, reserveGroups, startMinTime, startMaxTime, endMinTime, endMaxTime } = this.state;
        const { getFieldProps } = this.props.form;

        return showInfo ? <div>

            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <h2>预约分区:{info.partCode}</h2>
            <WhiteSpace size="lg" />
            <h3>预约日期:{dateFormat("mm月dd日", new Date(info.date))}</h3>
            <WhiteSpace size="lg" />
            <h3>预约时间:{dateFormat("HH:MM", new Date(info.startTime)) + "-" + dateFormat("HH:MM", new Date(info.endTime))}</h3>
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <WhiteSpace size="lg" />
            <Button type="primary" onClick={this.reReserve}>重新预约</Button>
        </div> : showForm ? <form>
            <List renderHeader={() => "预约工作区"}>
                <List.Item arrow="horizontal" extra={this.state.groupNum}>组别号</List.Item>
                <List.Item arrow="horizontal" extra={this.state.courseName}>报名课题</List.Item>
                <Picker data={partList}
                    cols={1}
                    {...getFieldProps('part', {
                        rules: [{ required: true, message: "未选择分区" }],
                        onChange: this.partChange
                    })}>
                    <List.Item arrow="horizontal">选择分区</List.Item>
                </Picker>

                <List.Item arrow="horizontal"
                    onClick={() => {
                        document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                        this.setState({
                            calendarShow: true,
                        });
                    }}
                    extra={this.state.date ? dateFormat("YYYY-mm-dd", this.state.date) : "选择预约日期"}
                >选择日期
                </List.Item>
                <DatePicker
                    mode="time"
                    disabled={date == null}
                    minuteStep={5}
                    {...getFieldProps('startTime', {
                        initialValue: this.state.dpValue,
                        rules: [
                            { required: true, message: '请选择预约开始时间' },
                            // { validator: this.validateDatePicker },
                        ],
                        onChange: this.startTimeChange
                    })}
                    minDate={startMinTime}
                    maxDate={startMaxTime}
                >
                    <List.Item arrow="horizontal">开始时间</List.Item>
                </DatePicker>
                <DatePicker
                    mode="time"
                    disabled={date == null}
                    minuteStep={5}
                    {...getFieldProps('endTime', {
                        initialValue: this.state.dpValue,
                        rules: [
                            { required: true, message: '请选择预约结束时间' },
                            // { validator: this.validateDatePicker },
                        ],
                        onChange: this.endTimeChange
                    })}
                    minDate={endMinTime}
                    maxDate={endMaxTime}
                >
                    <List.Item arrow="horizontal">结束时间</List.Item>
                </DatePicker>
                <Calendar
                    type="one"
                    visible={this.state.calendarShow}
                    onCancel={this.onCancel}
                    onConfirm={this.onConfirm}
                    defaultDate={now}
                    getDateExtra={this.getDateExtra}
                    minDate={new Date(+now)}
                    maxDate={new Date(now.getFullYear(), now.getMonth(), now.getDate() + 40)}
                />

                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Button type="primary" disabled={date == null} onClick={() => { this.showReserveDetail() }}>{'查看所选日期预约情况'}</Button>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Button type="primary" onClick={this.submit}>{'提交预约'}</Button>
            </List>
            <Modal
                visible={this.state.showReserve}
                transparent
                closable
                onClose={this.onClose}
                title="预约情况"
                footer={[{ text: '确定', onPress: () => { this.onClose(); } }]}
                wrapProps={{ onTouchStart: onWrapTouchStart }}
                afterClose={() => { }}
            >
                <div style={{ width: "100%", height: "100%" }}>
                    {
                        reserveGroups.length > 0 ? reserveGroups.map(g => {
                            return <Flex>
                                <Flex.Item style={{ flex: 1 }}>小组{g.groupNum}:</Flex.Item>
                                <Flex.Item style={{ flex: 2 }}>{dateFormat("HH:MM", new Date(g.startTime)) + "-" + dateFormat("HH:MM", new Date(g.endTime))}</Flex.Item>
                            </Flex>
                        }) : <div>暂无其他小组预约</div>
                    }
                </div>
            </Modal>
        </form > : null;

    }
}

export default createForm()(ReserveForm);
