import { Modal,Toast } from 'antd-mobile';

export const  showToast=(msg) =>{
  Toast.info(msg, 1);
}
export const showModal=(title,message)=>{
  Modal.alert(title, message)
}
export const showOkModal=(title,message,okCallback)=>{
  Modal.alert(title, message,[
    { text: '取消', onPress: () => {}},
    { text: '确定', onPress: ()=>{okCallback()}},
  ])
}
export const getSearchParam=(url)=>{
    var firstIndex = url.indexOf("?");
    var lastIndex = url.lastIndexOf("?");
    var index = url.indexOf("#");
    var search = "";
    if(firstIndex < index){
      search = url.slice(firstIndex+1,index)
    }else{
      search = url.substring(firstIndex+1)
    }

    if(firstIndex!=lastIndex){
      search = search + url.substring(lastIndex).replace("?","&")
    }
    var param = { };
    search.split("&").forEach(p=>{
      var kv = p.split("=");
      param[kv[0]]=kv[1]
    });
    return param;
}

export const dateFormat=(fmt, date)=> {
  let ret;
  const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
  };
  return fmt;
}

export const transDate = (timestamp) => {
  var tt = new Date(parseInt(timestamp));
  var days = parseInt((new Date().getTime() - timestamp) / 86400000);
  var today = new Date().getDate();
  var year = tt.getFullYear();
  var mouth = tt.getMonth() + 1;
  var day = tt.getDate();
  var time = tt.getHours() < 10 ? "0" + tt.getHours() : tt.getHours();
  var min = tt.getMinutes() < 10 ? "0" + tt.getMinutes() : tt.getMinutes();
  var result, offset;
　　　　　　　offset = Math.abs(today - day);
　　　　if (days < 4&&offset<4) {
　　　　　　　if (offset === 0) {
      result = "今天" + time + ":" + min;
    } else if (offset === 1) {
      result = "昨天" + time + ":" + min;
    } else if (offset === 2) {
      result = "前天" + time + ":" + min;
    }
  } else {
    result = year + "-" + mouth + "-" + day + " " + time + ":" + min;
  }
  return result;
}

export const getFormValidatorErrText= (error = {}) => {
  for (let key in error) {
    if (error[key]?.errors?.length > 0) {
      return error[key]?.errors[0].message;
    }
  }
  return '未知问题!';
}

export const showFormValidatorErr= (error = {}) => {
    showToast(getFormValidatorErrText(error))
}



function closest(el, selector) {
  const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
  while (el) {
      if (matchesSelector.call(el, selector)) {
          return el;
      }
      el = el.parentElement;
  }
  return null;
}

export const onWrapTouchStart = (e) => {
  // fix touch to scroll background page on iOS
  if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
  }
  const pNode = closest(e.target, '.am-modal-content');
  if (!pNode) {
      e.preventDefault();
  }
}