
import React from 'react';
import { WhiteSpace, List,Switch, Button, InputItem, Picker, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import 'antd-mobile/dist/antd-mobile.css';

import {
    autoLogin,
    addCourse,
    updateCourse,
    getLabList,
    getCourseDetail,
    deleteCourse
} from "../../js/request.js";


const courseType = [
    { value: 0, label: "自拟课题" },
    { value: 1, label: "发散性课题" },
    { value: 2, label: "收敛性课题" }
]

class AddCourse extends React.Component {

    
    constructor(props) {
        super(props);
        autoLogin();
        var courseId = this.props.match.params.id;
        this.state = {
            labList:[],
            courseId:parseInt(courseId),
            update: courseId !=undefined && courseId != null,
        }
        
        this.submit.bind(this);

    }

    componentDidMount() {
        const {update,courseId} = this.state;
        if(update && courseId){
            getCourseDetail(courseId).then(data => {
                if (data) {
                    this.setState({ detail: data })
                }
            })
        }
        getLabList(1,10000).then(data => {
            if (data) {
                this.setState({ labList: data.list })
            }
        })
    }
    handleClick = () => {
        this.inputRef.focus();
    }

    submit = () => {
        const update = this.state.update;
        this.props.form.validateFields((error, values) => {

            console.log(values)
            Toast.loading('', 10, () => {
                console.log('Load complete !!!');
            });
            var status = values.courseStatus?1:2
            var form = {
                ...values,
                courseId: update ? this.state.courseId : null,
                labId:values.labId[0],
                type: values.type[0],
                status,  
            }
            console.log(form)
            if (this.state.update) {
                updateCourse({ ...form }).then((data) => {
                    if (data) {
                        Toast.hide();
                        Toast.success('更新成功', 1);
                        this.props.history.goBack();
                    }
                })
            } else {
                addCourse({ ...form }).then((data) => {
                    if (data) {
                        Toast.hide();
                        Toast.success('添加成功', 1);
                        this.props.history.goBack();
                    }
                })
            }

        })
    }

    delete = ()=>{
        deleteCourse(this.state.courseId).then(data=>{
            this.props.history.goBack();
        })
    }

    render() {

        const {update,labList,detail} = this.state;
        const type = detail ? [detail.type] : [];
        const labData = labList.map(lab => { return { value: lab.labId, label: lab.labName }})
        const { getFieldDecorator, getFieldProps } = this.props.form;
        return <form>
            <List renderHeader={() => update ? '编辑课题' : '添加课题'}>
                <Picker data={labData} cols={1} {...getFieldProps('labId', {
                    rules: [{ required: true }],
                    initialValue: detail ? [detail.labId] : [],
                })} >
                    <List.Item arrow="horizontal">实验室</List.Item>
                </Picker>
                <InputItem
                    {...getFieldProps('code', {
                        rules: [{ required: true }],
                        initialValue: detail ? detail.courseCode : null
                    })}
                    clear
                    placeholder="课题编号"
                >课题编号</InputItem>
                <InputItem
                    {...getFieldProps('name', {
                        rules: [{ required: true }],
                        initialValue: detail ? detail.courseName : null
                    })}
                    clear
                    placeholder="课题名称"
                >课题名称</InputItem>
                <Picker data={courseType} cols={1} {...getFieldProps('type', {
                    rules: [{ required: true }],
                    initialValue: detail ? [detail.courseType] : [],
                })} >
                    <List.Item arrow="horizontal">课题类型</List.Item>
                </Picker>

                {this.state.update ? <List.Item
                    extra={<Switch
                        {...getFieldProps('courseStatus', {
                            initialValue: detail?detail.status==1?true:false:null,
                            valuePropName: 'checked',
                            onChange: (val) => {
                                detail.status = val?1:2;
                                this.setState({detail});
                            },
                        })}
                        onClick={(checked) => {
                            // set new value
                            this.props.form.setFieldsValue({
                                courseStatus: checked,
                            });
                        }}
                    />}
                >开放状态</List.Item> : null}

                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Button type="primary" onClick={this.submit}>{update ? '保存' : '添加'}</Button>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                {update?<Button type="warning" onClick={this.delete}>删除</Button>:null}
                
            </List>

        </form >;
    }
}

const AddCourseWrapper = createForm()(AddCourse);
export default AddCourseWrapper;