/* eslint-disable no-undef */
import { createHashHistory } from 'history';
import { Modal, Toast } from 'antd-mobile';
import {
    showToast
} from "./utils.js";
import {
    autoLogin,
    getWxConfig
} from './request.js'

const history = createHashHistory();
const wx = window.wx;
const alert = Modal.alert;



function closeWxWindow() {
    var url = window.location.href;
    getWxConfig({
        url
    }).then((data) => {
        wx.config({
            debug: url.search("localhost")!=-1, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.appId, // 必填，公众号的唯一标识
            timestamp: data.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.nonceStr, // 必填，生成签名的随机串
            signature: data.signature,// 必填，签名，见附录1
            jsApiList: [
                'closeWindow'
            ]
        })
        wx.ready(() => {
            wx.closeWindow();
        })
    })
}


export const handlerData = (status, res) => {
    Toast.hide();
    switch (res.code) {
        case 500:
            showToast(res.msg);
            break;
        case 400:
            showToast(res.msg);
            break;
        case 404:
            showToast(res.msg);
            break;
        case 401:
            console.log(res);
            localStorage.removeItem("Authorization");
            localStorage.removeItem("userInfo");
            autoLogin();
            break;
        case 40://完善信息
            var openid = res.msg;
            history.push("/register/" + openid);
            break;
        case 41://取消关注
            alert('退出', res.msg, [
                { text: '确认', onPress: () => closeWxWindow() },
            ])
            break;
        default:
            showToast(res.msg);
            console.log("未处理的类型:"+res);
    }
}
