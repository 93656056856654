
import React from 'react';
import { Modal, Toast } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import './adminLab.css';
import SimpleList from '../../component/SimpleList';

import {
    autoLogin,
    getLabList,
    getLabPassword
} from "../../js/request.js";

class EntryLab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            labList:[],
            partList: [],
            lockPassWd: ""
        }
    }

    componentDidMount() {
        autoLogin("",userInfo=>{
            getLabList(0, 100000).then(res=>{
                if(res){
                    this.setState({
                        labList:res.list
                    })
                }
            })
        });
    }

    onClose = () => {
        this.setState({
            showMsg: false
        })
    }
    getClock = (labId) => {

        Toast.loading('密码获取中', 5, () => {
            console.log('请求超时 !!!');
        });
        getLabPassword(labId).then(res=>{
            if(res){
                this.setState({
                    showMsg: true,
                    lockPassWd: res
                })
            }
        })
        
    }

    render() {
        let {labList} = this.state;
        let list = labList.map(l=>{
            return {id:l.labId,name:l.labName,extra:l.address}
        })
        return < div >
            <SimpleList title="选择实验室" list={list} itemClick={this.getClock}></SimpleList>
            <Modal
                visible={this.state.showMsg}
                transparent
                maskClosable={false}
                onClose={this.onClose}
                title="锁密码"
                footer={[{ text: '确认', onPress: () => { this.onClose(); } }]}
                wrapProps={{ onTouchStart: this.onWrapTouchStart }}
                afterClose={() => { }}
            >
                <div style={{ width: "100%", height: "100%" }}>
                    <h3>{this.state.lockPassWd}</h3>
                </div>
            </Modal>
        </div >;
    }
}

export default EntryLab;