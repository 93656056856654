
import React from 'react';
import { Switch, WhiteSpace, Calendar, List, Button, InputItem, Picker, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import 'antd-mobile/dist/antd-mobile.css';
import './editPart.css';
import {
    showFormValidatorErr
}from "../../js/utils.js"
import {
    autoLogin,
    addPart,
    updatePart,
    getPartDetail,
    getCourseList,
    getPeriod
} from "../../js/request.js";


const now = new Date();

class EditPart extends React.Component {

    constructor(props) {
        super(props);
        autoLogin();
        this.state = {
            labId : this.props.match.params.labId,
            update: false,
            extra:{},
            calendarShow: false,
            customCourse: false,
            courseList: [{ value: 0, label: "自选课题" }]
        };
        var partId = this.props.match.params.partId;
        if (partId) {
            this.state.update = true;
            getPartDetail(partId).then(data => {
                if (data) {
                    var {extra} = this.state
                    data.enableDates.forEach(date =>{
                        let isReserve = date.reserveGroups.filter(g=>g.groupId).length>0;
                        extra[+date.reserveDate] = { info:isReserve?"已预约":"开放", disable: isReserve }
                    })
                    this.setState({ extra,detail: data })
                }
            })
        } else {
            this.state.update = false;
        }
        this.submit.bind(this);
    }

    componentDidMount() {
        getPeriod(this.state.labId).then(data => {
            var period = data.period
            this.setState({
                period
            })
            getCourseList().then(data => {
                if (data && data.list) {
                    var courseList = data.list.filter(course=>course.labId==this.state.labId &&course.period == period && course.status==1).map(course => {
                        return { value: course.courseId, label: course.courseName }
                    })
                    console.log(courseList)
                    this.setState({ courseList })
                }
            })
          })
    }

    onCancel = () => {
        document.getElementsByTagName('body')[0].style.overflowY = this.originbodyScrollY;
        this.setState({
            calendarShow: false
        });
    }

    selectDate = (val) => {
        var {extra} = this.state
        if(extra[+val]){
            delete extra[+val];
        }else{
            extra[+val] = { info: '开放', disable: false };
        }
        this.setState({
            extra
        })
    }

    getDateExtra = date => this.state.extra[+date];

    submit = () => {
        const update = this.state.update;
        this.props.form.validateFields((error, values) => {
            if(error){
                showFormValidatorErr(error);
                return;
            }
            Toast.loading('', 5, () => {
                console.log('请求超时 !!!');
            });
            let isOpen = values.partStatus ? true : false
            let dateList = Object.keys(this.state.extra).map(s=>parseInt(s))
            let courseId = values.courseId[0]
            let form = {
                partId: update ? parseInt(this.props.match.params.partId) : null,
                labId: parseInt(this.props.match.params.labId),
                ...values,
                courseId,
                isOpen,
                dateList
            }
            console.log(form)
            if (this.state.update) {
                updatePart({...form}).then((data) => {
                    if(data){
                        Toast.hide();
                        Toast.success('更新成功', 1);
                        this.props.history.goBack();
                    } 
                })
            } else {
                addPart({...form}).then((data) => {
                    if(data){
                        Toast.hide();
                        Toast.success('添加成功', 1);
                        this.props.history.goBack();
                    }
                })
            }

        })
    }

    render() {

        const { courseList, update, detail } = this.state;

        const { getFieldDecorator, getFieldProps } = this.props.form;
        const days = Object.keys(this.state.extra)
        return <form>
            <List renderHeader={() => update ? '分区管理' : '添加分区'}>
                <InputItem
                    {...getFieldProps('partCode', { rules: [{ required: true ,message: '分区编号不能为空'}],
                    initialValue: detail ? detail.partCode : null 
                })}
                    clear
                    placeholder="分区编号"
                >分区编号</InputItem>
                <Picker data={courseList}
                    cols={1}

                    {...getFieldProps('courseId', {
                        rules: [{ required: true ,message: '课题不能为空'}],
                        onChange: this.courseChange,
                        initialValue: detail&&detail.courseId ? [detail.courseId] : [],
                    })} >
                    <List.Item arrow="horizontal">分配课题</List.Item>
                </Picker>
                
                <List.Item arrow="horizontal"
                    onClick={() => {
                        document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
                        this.setState({
                            calendarShow: true,
                        });
                    }}
                    extra={days.length + "天"}
                >
                    设置开放时间
                </List.Item>
                <Calendar
                    type="one"
                    title="课题开放时间"
                    visible={this.state.calendarShow}
                    onCancel={this.onCancel}
                    onConfirm={this.onCancel}
                    onSelect={this.selectDate}
                    defaultDate={now}
                    getDateExtra={this.getDateExtra}
                    minDate={new Date(+now)}
                    maxDate={new Date(now.getFullYear(), now.getMonth(), now.getDate()+40)}
                />
                {this.state.update ? <List.Item
                    extra={<Switch
                        {...getFieldProps('partStatus', {
                            initialValue: detail?detail.isOpen?true:false:null,
                            valuePropName: 'checked',
                            onChange: (val) => {
                                detail.isOpen = val?1:0;
                                this.setState({detail});
                            },
                        })}
                        onClick={(checked) => {
                            // set new value
                            this.props.form.setFieldsValue({
                                partStatus: checked
                            });
                        }}
                    />}
                >开放状态</List.Item> : null}

                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Button type="primary" onClick={this.submit}>{update ? '保存' : '添加'}</Button>
            </List>

        </form >;
    }
}

const EditPartWrapper = createForm()(EditPart);
export default EditPartWrapper;