
import React from 'react';
import ReactDOM from 'react-dom';
import { Accordion, NavBar, WhiteSpace, List } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import {
    transDate
} from "../../js/utils.js";
import {
    autoLogin,
    getNewsList
} from "../../js/request.js";


const NUM_ROWS = 20;


class NewsShow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            resultShowList: [],
            studySourceList: [],
            activeKey: []
        };
    }

    componentDidMount() {
        autoLogin(null, async (userInfo) => {
            const newslist = await getNewsList();
            if (newslist) {
                var resultShowList = newslist.list.filter(news => news.author.indexOf("成果展示")>=0);
                var studySourceList = newslist.list.filter(news => news.author.indexOf("研学资源")>=0);
                this.setState({
                    resultShowList,
                    studySourceList
                })
            }
            console.log(newslist)
        });
    }

    componentDidUpdate() {

    }

    handleClick = (url) => {
        console.log(url)
        window.location.href = url;
    }

    panelChange = (key) => {
        this.setState({
            activeKey: key
        });
    }

    render() {

        const getExtra = (news) => {
            return news.picUrl && news.picUrl.length>0?<img style={{ height: '64px', width: '64px', marginRight: '5px' }} src={news.picUrl} alt={news.title} />:null
        }

        const getList = (list) => {
            return <List>
                {
                    list.map(news => <List.Item key={news.updateTime} extra={getExtra(news)} onClick={(e) => this.handleClick(news.url)} >
                        <div style={{ lineHeight: 1 }}>
                            <div style={{ marginBottom: '20px' }}>{news.title}</div>
                            <div style={{ fontSize: '14px', color: '#ccc' }}>{transDate(news.updateTime)}</div>
                        </div>
                    </List.Item>)
                }
            </List>
        }

        const { resultShowList, studySourceList } = this.state;
        return < div >
            <Accordion activeKey={this.state.activeKey} style={{}} onChange={this.panelChange}>
                <Accordion.Panel key="0" header="成果展示">
                    {getList(resultShowList)}
                </Accordion.Panel>
                <Accordion.Panel key="1" header="研学资源">
                    {getList(studySourceList)}
                </Accordion.Panel>
            </Accordion>

        </div >
    }
}

export default NewsShow;