
import React from 'react';
import { Modal } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import SimpleList from '../../component/SimpleList';

import {
    autoLogin,
    getLabList
} from "../../js/request.js";

class ReserveLab extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            labList: []
        }
    }

    componentDidMount() {
        autoLogin("",async (userInfo)=>{
            let res = await getLabList(0, 100000)
            this.setState({
                labList:res.list
            })
        });
    }
    onClose = () => {
        this.setState({
            showMsg: false
        })
    }
    toReserve = (labId) => {
        this.props.history.push("/lab/reserve/" + labId);
    }

    render() {
        let {labList} = this.state;
        let list = labList.map(l=>{
            return {id:l.labId,name:l.labName,extra:l.address}
        })
        return < div >
            <SimpleList title="选择实验室" list={list} itemClick={this.toReserve}></SimpleList>
        </div >;
    }
}

export default ReserveLab;