
import React from 'react';
import { Flex,  List } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import './applyList.css';
import {
    transDate
} from "../../js/utils.js";
import {
    autoLogin,
    getLockRecord
} from "../../js/request.js";


class ApplyList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
        };
    }

    componentDidMount() {
        autoLogin("",(user)=>{
            getLockRecord().then(res => {
                if (res) {
                    this.setState({ list: res.list })
                }
            })
        });
        
    }

    render() {
        const { list } = this.state;
        return < div >
            <List renderHeader={() => '开锁记录'}>
                {
                    list.map(row => <List.Item key={row.id}>
                        <Flex>
                        <Flex.Item style={{fontSize: '14px',color:"red",flex:4}}>{row.userName}</Flex.Item>
                            <Flex.Item style={{fontSize: '12px',flex:1}}>在</Flex.Item>
                            
                            <Flex.Item style={{fontSize: '14px',flex:8}}>{transDate(row.time)}</Flex.Item>
                            {/* 使用密码:
                            <Flex.Item >{row.password}</Flex.Item> */}
                            <Flex.Item style={{fontSize: '12px',flex:2}}>进入</Flex.Item>
                            <Flex.Item style={{fontSize: '14px',flex:8}}>{row.labName}</Flex.Item>
                        </Flex>
                    </List.Item>)
                }
            </List>
        </div >
    }
}

export default ApplyList;