import React, { Component } from 'react';
import { HashRouter as Router, Link, Route } from 'react-router-dom';
import './App.css';
import Login from './page/Login';
import AddLab from './page/lab/AddLab';
import AdminLab from './page/lab/AdminLab';
import LabList from './page/lab/LabList';
import ApplyLab from './page/lab/ApplyLab';
import ApplyList from './page/lab/ApplyList';
import LabUserList from './page/lab/LabUserList';
import EntryLab from './page/lab/EntryLab';
import ApplyMsg from './page/lab/ApplyMsg';
import LockRecord from './page/lab/LockRecord';

import Period from './page/lab/Period';

import EditPart from './page/part/EditPart';

import ReserveForm from './page/course/ReserveForm';
import Reserve from './page/course/Reserve';

import AddCourse from './page/course/AddCourse';
import CourseList from './page/course/CourseList';
import CourseShow from './page/course/CourseShow';

import Register from './page/Register';
import RegisterCode from './page/RegisterCode';
import MpAdmin from './page/MpAdmin';

import NewsShow from './page/news/NewsShow';

class App extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Router>
        <div className="App">
          {/* <Link to="/">Home</Link>
          <Link to="/About">About</Link>
          <Link to="/Product">Product</Link>
          <hr/> */}
          <Route path="/" exact component={Login}></Route>
          <Route path="/login" exact component={Login}></Route>
          <Route path="/regist/code" exact component={RegisterCode}></Route>
          <Route path="/register/:openId" exact component={Register}></Route>

          <Route path="/mp/admin" exact component={MpAdmin}></Route>

          <Route path="/lab/period" exact component={Period}></Route>
          <Route path="/lab/list" exact component={LabList}></Route>
          <Route path="/lab/edit/:id"  exact component={AddLab}></Route>
          <Route path="/lab/add"  exact component={AddLab}></Route>
          <Route path="/lab/apply"  exact component={ApplyLab}></Route>
          <Route path="/lab/admin"  exact component={AdminLab}></Route>
          <Route path="/lab/entry"  exact component={EntryLab}></Route>
          <Route path="/lab/apply/admin"  exact component={ApplyList}></Route>
          <Route path="/lab/user/list"  exact component={LabUserList}></Route>
          <Route path="/lab/reserve"  exact component={Reserve}></Route>
          <Route path="/lab/reserve/:labId"  exact component={ReserveForm}></Route>
          <Route path="/lab/apply/msg/:groupId"  exact component={ApplyMsg}></Route>
          <Route path="/lab/lock/record"  exact component={LockRecord}></Route>

          <Route path="/part/edit/:labId/:partId"  exact component={EditPart}></Route>
          <Route path="/part/add/:labId"  exact component={EditPart}></Route>

          <Route path="/course/show" exact component={CourseShow}></Route>
          <Route path="/course/list" exact component={CourseList}></Route>
          <Route path="/course/edit/:id"  exact component={AddCourse}></Route>
          <Route path="/course/add"  exact component={AddCourse}></Route>

          <Route path="/news/list"  exact component={NewsShow}></Route>

        </div>
      </Router>
    );
  }
}

export default App;

