
import React from 'react';
import { WhiteSpace, List, Button, InputItem, Switch, Picker, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import 'antd-mobile/dist/antd-mobile.css';
import './addLab.css';
import {
    autoLogin,
    addLab,
    updateLab,
    getLabDetail,
    getLabAdminList
} from "../../js/request.js";


class AddLab extends React.Component {

    constructor(props) {
        super(props);
        autoLogin();
        var labId = this.props.match.params.id;
        if (labId) {
            this.state = { update: true };
            getLabDetail(labId).then(data => {
                if(data){
                    this.setState({ detail:data })
                }
            })
        } else {
            this.state = { update: false };
        }
        this.submit.bind(this);
    }

    componentDidMount() {
        getLabAdminList().then(data=>{
            console.log(data);
            var administrators = [];
            if(data && data.list){
                administrators = data.list.map(user =>{
                    return {value:user.id,label:user.realName}
                })
            }
            
            this.setState({administrators})
        })
    }
    handleClick = () => {
        this.inputRef.focus();
    }
    
    submit = () => {
        const update = this.state.update;
        this.props.form.validateFields((error, values) => {

            Toast.loading('', 10, () => {
                console.log('Load complete !!!');
            });
            var status = values.labStatus?1:0
            var administrator = values.admin?values.admin[0]:null;
            var form = {
                labId: update?parseInt(this.props.match.params.id):null,
                ...values,
                status,
                administrator
            }
            console.log(form)
            if (this.state.update) {
                updateLab({...form}).then((data) => {
                    if(data){
                        Toast.hide();
                        Toast.success('更新成功', 1);
                        this.props.history.goBack();
                    } 
                })
            } else {
                addLab({...form}).then((data) => {
                    if(data){
                        Toast.hide();
                        Toast.success('添加成功', 1);
                        this.props.history.goBack();
                    }
                })
            }

        })
    }

    render() {

        const {administrators,update,detail}= this.state;
        console.log(detail);
        console.log(administrators)
        const { getFieldProps } = this.props.form;
        return <form>
            <List renderHeader={() => update ? '编辑实验室' : '添加实验室'}>
                <InputItem
                    {...getFieldProps('labName',{initialValue:detail?detail.labName:null})}
                    clear
                    placeholder="实验室名称"
                >实验室名称</InputItem>
                <InputItem
                    {...getFieldProps('address',{initialValue:detail?detail.address:null})}
                    clear
                    placeholder="学校"
                >学校</InputItem>
                <InputItem
                    {...getFieldProps('lockNum',{initialValue:detail?detail.lockNum:null})}
                    clear
                    placeholder="门锁编号"
                >门锁编号</InputItem>
                <InputItem
                    {...getFieldProps('lockPassword',{initialValue:detail?detail.lockPassword:null})}
                    clear
                    placeholder="门锁密码"
                >门锁密码</InputItem>
                <Picker data={administrators} cols={1} {...getFieldProps('admin', {
                    rules: [{ required: true }],
                    initialValue: detail ? [detail.administrator] : [],
                })} >
                    <List.Item arrow="horizontal">管理员(可选)</List.Item>
                </Picker>

                {this.state.update ? <List.Item
                    extra={<Switch
                        {...getFieldProps('labStatus', {
                            initialValue: detail?detail.status==1?true:false:null,
                            valuePropName: 'checked',
                            onChange: (val) => {
                                detail.status = val?1:0;
                                this.setState({detail});
                            },
                        })}
                        onClick={(checked) => {
                            // set new value
                            this.props.form.setFieldsValue({
                                labStatus: checked,
                            });
                        }}
                    />}
                >开放状态</List.Item> : null}

                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <Button type="primary" onClick={this.submit}>{update ? '保存' : '添加'}</Button>
            </List>

        </form >;
    }
}

const AddLabWrapper = createForm()(AddLab);
export default AddLabWrapper;