
import React from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom';
import { Flex, WhiteSpace, List, PullToRefresh, ListView, Button } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import {
    PlusCircleOutlined
} from '@ant-design/icons';

import {
    autoLogin,
    getLabList
} from "../../js/request.js";


const NUM_ROWS = 20;
const statusCode = [
    {code:0,text:"关闭",color:"red"},
    {code:1,text:"开放",color:"green"}
]
const getStatus = (code)=>{

    let status = statusCode.filter(s=>s.code==code)[0]
    return <div style={{color:status.color}}>{status.text}</div>
}
class LabList extends React.Component {

    constructor(props) {
        super(props);
        
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2,
        });
        this.state = {
            dataSource,
            total: NUM_ROWS,
            page: 0,
            labList: [],
            refreshing: true,
            isLoading: false,
            height: document.documentElement.clientHeight,
            useBodyScroll: true,
            hasMore: true
        };
        //this.toEdit.bind(this);

    }

    componentDidMount() {
        const hei = this.state.height - ReactDOM.findDOMNode(this.lv).offsetTop;
        this.setState({
            height: hei,
        });
        autoLogin(null, async (userInfo) => {
            this.onEndReached();
        });
        
    }

    componentDidUpdate() {
        if (this.state.useBodyScroll) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }

    onEndReached = (event) => {

        if (this.state.isLoading || !this.state.hasMore) {
            return;
        }
        this.setState({ isLoading: true });
        var page = this.state.page;
        getLabList(page++, NUM_ROWS).then(data => {
            if(data){
                const labList = this.state.labList.concat(data.list);
                const hasMore = (labList.length < data.total)
                this.setState({
                    total: data.total,
                    page,
                    labList,
                    dataSource: this.state.dataSource.cloneWithRows(labList),
                    isLoading: false,
                    hasMore
                })
            }
            
        })
    };

    handleClick = () => {
        this.inputRef.focus();
    }

    toEdit = (id) => {
        this.props.history.push("/lab/edit/" + id);
    }

    toAdd = (e) => {
        this.props.history.push("/lab/add");
    }

    render() {

        const row = (rowData, sectionID, rowID) => {

            const lab = this.state.labList[rowID];
            return (
            <List.Item key={lab.labId} arrow="horizontal" onClick={(e) => this.toEdit(lab.labId)} extra={getStatus(lab.status)}>{lab.labName}</List.Item>
            );
        };
        
        return < div >
            <List renderHeader={() => '实验室管理'}>
                <List.Item key="0" onClick={(e) => this.toAdd(e)}>
                    <Flex justify="between">
                        <div>添加实验室</div>
                        <PlusCircleOutlined />
                    </Flex>
                </List.Item>

            </List>

            <WhiteSpace />
            <ListView

                initialListSize={NUM_ROWS}
                style={this.state.useBodyScroll ? {} : {
                    height: this.state.height,
                    border: '1px solid #ddd',
                    margin: '5px 0',
                }}
                key={this.state.useBodyScroll ? '0' : '1'}
                ref={el => this.lv = el}
                dataSource={this.state.dataSource}
                renderFooter={() => (<div style={{ padding: 10, textAlign: 'center' }}>
                    {this.state.isLoading || this.state.hasMore ? 'Loading...' : '没有更多了'}
                </div>)}
                renderRow={row}
                useBodyScroll={this.state.useBodyScroll}
                // pullToRefresh={<PullToRefresh
                //     refreshing={this.state.refreshing}
                //     onRefresh={this.onRefresh}
                // />}
                onEndReached={this.onEndReached}
                pageSize={NUM_ROWS}
            />

        </div >
    }
}

export default LabList;