
import React from 'react';
import { Flex, WhiteSpace, List, Button, InputItem } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';
import { createForm } from 'rc-form';
import {
  showFormValidatorErr
} from "../js/utils.js"
import {
  register
} from "../js/request.js";


class Register extends React.Component {


  constructor(props) {
    super(props)
    var { openId } = this.props.match.params;
    this.state = {
      openId
    }
    console.log(this.state)
  }

  componentDidMount() {

  }

  componentWillReceiveProps(newProps) {

  }
  shouldComponentUpdate(newProps, newState) {
    return true;
  }
  componentWillUpdate(nextProps, nextState) {

  }
  getSnapshotBeforeUpdate(prevProps, prevState) { // 组件更新前触发

  }
  componentDidUpdate(prevProps, prevState) {

  }
  componentWillUnmount() {

  }

  submit = () => {
    this.props.form.validateFields((error, values) => {

      if (error) {
        showFormValidatorErr(error);
        return;
      }
      var form = {
        ...values,
        openId: this.state.openId,
        mobile: values.mobile.replace(/ /g,""),
        realName: values.realName
      }
      console.log(form);
      register(form).then((data)=>{
        this.props.history.goBack();
      })
    })
  }


  render() {
    const { getFieldDecorator, getFieldProps } = this.props.form;
    return <form>
      <List renderHeader={"完善信息"}>
        <InputItem
          {...getFieldProps('realName',{
            rules: [{ required: true, message: '请输入姓名' }]
          })}
          clear
          placeholder="真实姓名"
        >真实姓名</InputItem>
        <InputItem
          {...getFieldProps('mobile',{
            rules: [{ required: true, message: '请输入手机号' }]
          })}
          clear
          type="phone"
          placeholder="手机号"
        >手机号</InputItem>
        <InputItem
          {...getFieldProps('email',{
            rules: [{ required: true, message: '请输入邮箱' }]
          })}
          clear
          type="email"
          placeholder="邮箱"
        >邮箱</InputItem>
        <InputItem
          {...getFieldProps('wxNum',{
            rules: [{ required: true, message: '请输入微信号' }]
          })}
          clear
          placeholder="微信号"
        >微信号</InputItem>

        <InputItem
          {...getFieldProps('password',{
            rules: [{ required: true, message: '注册码不能为空' }]
          })}
          clear
          placeholder="向管理员索要注册码"
        >注册码</InputItem>



        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <WhiteSpace size="lg" />
        <Button type="primary" onClick={this.submit}>保存</Button>
      </List>

    </form >;
  }
}

const RegisterWrapper = createForm()(Register);
export default RegisterWrapper;