
import React from 'react';
import { SegmentedControl, Flex, WhiteSpace, List, Modal, Toast } from 'antd-mobile';
import { Table } from 'antd';
import 'antd-mobile/dist/antd-mobile.css';
import 'antd/dist/antd.css';
import './applyList.css';
import {
    dateFormat,
    transDate,
    onWrapTouchStart
} from "../../js/utils.js";
import {
    autoLogin,
    getAllApplyList,
    getReserveHistory,
    getPeriod
} from "../../js/request.js";

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
    }, {
        title: '研学周期',
        dataIndex: 'period',
        key: 'period',
    }, {
        title: '组别符',
        dataIndex: 'groupNum',
        key: 'groupNum',
    }, {
        title: '课题编号',
        dataIndex: 'courseCode',
        key: 'courseCode',
    }, {
        title: '课题名称',
        dataIndex: 'courseName',
        key: 'courseName',
    }, {
        title: '学校',
        dataIndex: 'school',
        key: 'school',
    }, {
        title: '入学年份',
        dataIndex: 'startSchool',
        key: 'startSchool',
        render: text => dateFormat("YYYY", new Date(text))
    }, {
        title: '班级',
        dataIndex: 'className',
        key: 'className',
    }, {
        title: '填报人',
        dataIndex: 'userName',
        key: 'userName',
    }, {
        title: '同组人',
        dataIndex: 'studentList',
        key: 'studentList',
        render: text => text.map(s => s.userName).join("、")
    }, {
        title: '报名时间',
        dataIndex: 'registerTime',
        key: 'registerTime',
        render: text => transDate(text)
    }, {
        title: '预约状态码',
        dataIndex: 'statusCode',
        key: 'statusCode',
        render: text => text == 4 ? "1" : "0"
    }, {
        title: '自助码',
        dataIndex: 'selfCode',
        key: 'selfCode',
        render: text => text ? "1" : "0"
    }
];

class LabUserList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showMsg: false,
            tableModle: false,
            userList: [],
            reserveInfos: [],
            refreshing: true,
            isLoading: false,
            useBodyScroll: true,
        };
    }

    componentDidMount() {
        autoLogin("", userInfo => {
            // getPeriod(labId).then(data => {
            //     this.setState({
            //         period:data.period
            //     })
            // })
            getAllApplyList().then(res => {
                if (res) {
                    this.setState({ userList: res.list })
                }
            })
        });

    }


    showReserve = (reserveList) => {
        //e.preventDefault();
        this.setState({
            reserveInfos: reserveList,
            showReserve: true
        })
        // Toast.loading('', 3);
        // getReserveHistory(groupId).then(data=>{
        //     if(data){
        //         this.setState({
        //             reserveInfos:data.list,
        //             showReserve:true
        //         })
        //     }
        // })
    }

    onClose = () => {
        this.setState({
            showReserve: false
        })
    }

    onChange = (e) => {
        this.setState({
            tableModle: (e.nativeEvent.selectedSegmentIndex == 1)
        })
    }


    render() {

        const { tableModle, reserveInfos } = this.state;
        let index = 1;
        const userList = this.state.userList.filter(u => u.statusCode >= 3).map(u => {
            u.index = index;
            index++;
            return u;
        })

        return < div >
            <SegmentedControl
                values={['列表模式', '表格模式']}
                selectedIndex={tableModle ? 1 : 0}
                style={{ height: '40px' }}
                onChange={this.onChange}
            />
            {
                tableModle ? <Table columns={columns} dataSource={userList} title={() => '学生小组'} pagination={false} /> : <List renderHeader={() => '学生小组'}>
                    {
                        userList.map(row =>
                            <div>
                                <List.Item key={row.id} >
                                    <div key="1" style={{ padding: '0 15px' }}>
                                        <div
                                            style={{
                                                lineHeight: '50px',
                                                color: '#888',
                                                fontSize: 18,
                                                borderBottom: '1px solid #F6F6F6',
                                            }}
                                        >小组{row.groupNum}:{row.userName + " " + row.studentList.map(s => s.userName).join(" ")}</div>
                                        <div style={{ lineHeight: 2 }}>
                                            <Flex>
                                                <Flex.Item className="lable">实验室:</Flex.Item>
                                                <Flex.Item className="name">{row.labName}</Flex.Item>
                                            </Flex>
                                            <Flex>
                                                <Flex.Item className="lable">研学周期:</Flex.Item>
                                                <Flex.Item className="name">{row.period}</Flex.Item>
                                            </Flex>
                                            <Flex>
                                                <Flex.Item className="lable">课题:</Flex.Item>
                                                <Flex.Item className="name">{row.courseCode + " " + row.courseName}</Flex.Item>
                                            </Flex>
                                            <Flex>
                                                <Flex.Item className={"lable"}>学校:</Flex.Item>
                                                <Flex.Item className="name">{row.school}</Flex.Item>
                                            </Flex>
                                            <Flex>
                                                <Flex.Item className="lable">入学年份:</Flex.Item>
                                                <Flex.Item className="name">{dateFormat("YYYY", new Date(row.startSchool))}</Flex.Item>
                                            </Flex>
                                            <Flex>
                                                <Flex.Item className="lable">报名时间:</Flex.Item>
                                                <Flex.Item className="name">{transDate(row.registerTime)}</Flex.Item>
                                            </Flex>
                                            <Flex>
                                                <Flex.Item className={"lable"}>预约权限:</Flex.Item>
                                                <Flex.Item className="name">{row.statusCode == 4 ? "允许" : "不允许"}</Flex.Item>
                                            </Flex>
                                            <Flex>
                                                <Flex.Item className={"lable"}>最新预约信息:</Flex.Item>
                                                <Flex.Item className="name">
                                                    {
                                                        row.reserveList && row.reserveList.length > 0 ? <Flex>
                                                            {dateFormat("mm月dd日", new Date(row.reserveList[row.reserveList.length - 1].date))}
                                                            {dateFormat("HH:MM", new Date(row.reserveList[row.reserveList.length - 1].startTime)) + "-" + dateFormat("HH:MM", new Date(row.reserveList[row.reserveList.length - 1].endTime))}
                                                        </Flex> : <div>暂无</div>
                                                    }
                                                </Flex.Item>
                                            </Flex>
                                            <Flex>
                                                <Flex.Item className={"lable"}>历史预约信息:</Flex.Item>
                                                <Flex.Item className="name">
                                                    {
                                                        row.reserveList && row.reserveList.length > 0 ?
                                                            <div onClick={(e) => this.showReserve(row.reserveList)} style={{ color: "#108ee9" }}>查看</div> :
                                                            <div>暂无</div>
                                                    }
                                                </Flex.Item>
                                            </Flex>
                                            <Flex>
                                                <Flex.Item className={"lable"}>自助进入:</Flex.Item>
                                                <Flex.Item className="name">{row.selfCode ? "允许" : "不允许"}</Flex.Item>
                                            </Flex>


                                        </div>
                                    </div>
                                </List.Item>
                                <WhiteSpace style={{
                                    backgroundColor: '#F5F5F9',
                                    height: 8,
                                    borderTop: '1px solid #ECECED',
                                    borderBottom: '1px solid #ECECED',
                                }} />
                            </div>)
                    }
                </List>}
            <Modal
                visible={this.state.showReserve}
                transparent
                closable
                onClose={this.onClose}
                title="预约情况"
                footer={[{ text: '确定', onPress: () => { this.onClose(); } }]}
                wrapProps={{ onTouchStart: onWrapTouchStart }}
                afterClose={() => { }}
            >
                <div style={{ width: "100%", height: "100%" }}>
                    {
                        reserveInfos.length > 0 ? reserveInfos.map(g => {
                            return <Flex>

                                <Flex.Item style={{ flex: 4 }}>{dateFormat("mm月dd日", new Date(g.date))}:</Flex.Item>
                                <Flex.Item style={{ flex: 5 }}>{dateFormat("HH:MM", new Date(g.startTime)) + "-" + dateFormat("HH:MM", new Date(g.endTime))}</Flex.Item>
                                <Flex.Item style={{ flex: 3 }}>{g.partCode}</Flex.Item>
                            </Flex>
                        }) : <div>暂无预约信息</div>
                    }
                </div>
            </Modal>
        </div >
    }
}

export default LabUserList;