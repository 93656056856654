
import React from 'react';
import { Flex, NavBar, List, DatePicker, InputItem, WhiteSpace, Picker, Button, Modal, Toast } from 'antd-mobile';
import { PlusCircleOutlined } from '@ant-design/icons';
import { createForm } from 'rc-form';
import {
  showFormValidatorErr,
  showToast,
  dateFormat,
  transDate
} from "../../js/utils.js"
import {
  autoLogin,
  getLabCourseList,
  getLabList,
  addLabUser,
  updateLabUser,
  getApplyMsgByLab
} from "../../js/request.js";


class ApplyLab extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      update: false,
      showDetail: false,
      apply: {},
      labId: null,
      labName: "",
      labList: [],
      courseList: [],
      groupSize: 0
    }
  }
  componentDidMount() {
    //获取数据
    autoLogin("", async (userInfo) => {
      let res = await getLabList(0, 100000)
      this.setState({
        labList: res.list
      })
    });

  }

  labChange = (labId) => {
    const labName = this.state.labList.find((l) => l.labId == labId).labName;
    this.setState({ labId, labName })
    getApplyMsgByLab(labId).then(data => {
      if (data) {
        if (data.statusCode != 1) {
          this.props.history.push("/lab/apply/msg/" + data.groupId);
        } else {
          this.setState({
            update: true,
            id:data.groupId,
            apply: data,
            groupSize: data.students.length
          })
        }
      } else {
        this.setState({
          update: false,
          apply: {},
          groupSize: 0
        })
      }
      this.setState({
        showDetail: true
      })
      console.log(this.state.update)
    })
    getLabCourseList(labId).then(data => {
      if (data) {
        this.setCourseList(data)
      }
    })
  }


  setCourseList = (data) => {

    const list = data.list.filter(c => c.status == 1);
    let obj = {};
    let courseList = list.reduce((cur, next) => {
      // eslint-disable-next-line no-unused-expressions
      obj[next.courseId] ? "" : obj[next.courseId] = true && cur.push(next);
      return cur;
    }, []).map(course => {
      return { value: course.courseId, label: course.courseCode + ":" + course.courseName }
    })
    if (data) {
      this.setState({
        courseList
      })
    }
  }

  toAddPartner = (e) => {
    if (this.state.groupSize < 2) {
      this.setState({ groupSize: this.state.groupSize + 1 })
    } else {
      showToast("最多两个同组人");
    }
  }


  submit = () => {
    this.props.form.validateFields((error, values) => {
      if (error) {
        showFormValidatorErr(error);
        return;
      }
      let groupArray = Array.from({ length: this.state.groupSize }, (v, k) => k);
      let studentList = groupArray.map(index => {
        return { "userId": index, "userName": values["sameTeam" + index] }
      })
      let startSchool = values["startSchool"];
      startSchool.setMonth(8);//0-11
      startSchool.setDate(1);

      var form = {
        ...values,
        courseId: values.courseItem[0], //课题
        labId: this.state.labId,  //实验室
        school: values.school,    //学校
        className: values.className,  //班级
        startSchool,
        studentList
      }
      console.log(form)
      Modal.alert('加入实验室', '申请进入实验室', [
        {
          text: '考虑一下', style: 'cancel', onPress: () => { },
        }, {
          text: '提交',
          onPress: () => {
            Toast.loading('', 5, () => {
              console.log('请求超时 !!!');
            });
            if(this.state.update){
              updateLabUser({
                id:this.state.id,
                ...form
              }).then(res=>{
                if(res){
                  Toast.hide();
                }
              })
            }else{
              addLabUser(form).then(res=>{
                if(res){
                  Toast.hide();
                }
              })
            }
          }
        },
      ]);

    })
  }

  render() {

    const { labName, showDetail, courseList, update, apply, labList, groupSize } = this.state;
    let groupArray = Array.from({ length: groupSize }, (v, k) => k);

    const { getFieldProps } = this.props.form;
    return (showDetail ? <form>
      <NavBar
        mode="light"
        leftContent="返回"
        onLeftClick={(e) => { this.setState({ showDetail: false }) }}
      >{labName}</NavBar>
      <List renderHeader={() => '报名信息'}>
        <Picker
          data={courseList}
          cols={1}
          {...getFieldProps('courseItem', {
            rules: [{ required: true, message: '请选择课题' }],
            initialValue: update ? [apply.courseId] : [],
          })}
        >
          <List.Item arrow="horizontal">课题</List.Item>
        </Picker>

        <InputItem
          placeholder="请输入学校"
          {...getFieldProps('school', {
            rules: [{ required: true, message: '请输入学校名称' }],
            initialValue: update ? apply.school : null
          })}
          clear
        >学校</InputItem>
        <DatePicker
          mode="year"
          format={(value) =>{
            return dateFormat("YYYY",value) + "年";
          }}
          {...getFieldProps('startSchool', {
            rules: [{ required: true, message: '请输入入学年份' }],
            initialValue: update &&apply.startSchool? new Date(apply.startSchool) : null
          })}
        >
          <List.Item arrow="horizontal">入学年份</List.Item>
        </DatePicker>
        <InputItem
          {...getFieldProps('className', {
            rules: [{ required: true, message: '请输入班级名称' }],
            initialValue: update ? apply.className : null
          })}
          placeholder="请输入班级"
          clear
        >班级</InputItem>
      </List>
      <WhiteSpace />
      <List>
        <List.Item key="0" onClick={(e) => this.toAddPartner(e)}>
          <Flex justify="between">
            <div>添加同组人</div>
            <PlusCircleOutlined />
          </Flex>
        </List.Item>
        {
          groupArray.map(index => <InputItem key={index}
            {...getFieldProps('sameTeam' + index, {
              rules: [{ required: true, message: '同组人姓名未填全' }],
              initialValue: update && apply.students[index] ? apply.students[index].userName : null
            })}
            placeholder="同组人姓名"
            clear
          >同组人{index + 1}</InputItem>)
        }
      </List>
      <WhiteSpace />
      <WhiteSpace />
      <WhiteSpace />
      <Button type="primary" onClick={this.submit}>{update ? '修改' : '提交'}</Button>

    </form> : <List renderHeader={() => '选择实验室'}>
        {
          labList.map(row => <List.Item key={row.labId} arrow="horizontal" extra={row.address} onClick={(e) => this.labChange(row.labId)}>{row.labName}</List.Item>)
        }
      </List>
    );
  }
}

const ApplyLabWrapper = createForm()(ApplyLab)
export default ApplyLabWrapper;