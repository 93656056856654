
import React from 'react';
import { WhiteSpace, Modal, Button } from 'antd-mobile';
import copy  from 'copy-to-clipboard';
import 'antd-mobile/dist/antd-mobile.css';

import {
  registerCode,
  autoLogin
} from "../js/request.js";

import {
  onWrapTouchStart
} from "../js/utils.js";

class RegisterCode extends React.Component {

  constructor(props) {
    super(props)
    autoLogin();
    this.state = {
      code: "",
      showMsg: false
    }
  }

  componentDidMount() {

  }

  submit = () => {

    registerCode().then((data) => {
      if(data){
        this.setState({
          code: data,
          showMsg: true
        })
      }
      
    })
  }

  onClose = () => {
    this.setState({
      showMsg: false
    })
  }

  copyCode = () => {

    copy(this.state.code);
    this.onClose();

  }

  render() {
    return <div>

      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <WhiteSpace size="lg" />
      <Button type="primary" onClick={this.submit}>生成一次性注册码</Button>

      <Modal
        visible={this.state.showMsg}
        transparent
        closable
        maskClosable={false}
        onClose={this.onClose}
        title="注册码"
        footer={[{ text: '复制', onPress: () => { this.copyCode(); } }]}
        wrapProps={{ onTouchStart: onWrapTouchStart }}
        afterClose={() => { }}
      >
        <div>{this.state.code}</div>
      </Modal>

    </div >;
  }
}

export default RegisterCode;