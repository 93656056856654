
import React from 'react';
import { WhiteSpace } from 'antd-mobile';
import 'antd-mobile/dist/antd-mobile.css';

import {
    autoLogin,
    getApplyMsg
} from "../../js/request.js";

class ApplyMsg extends React.Component {

    constructor(props) {
        super(props);
        var groupId = this.props.match.params.groupId;
        this.state = {
            apply: false,
            groupId,
            msg: {},
            show: false
        }
    }

    componentDidMount() {
        autoLogin("", (user) => {
            getApplyMsg(this.state.groupId).then(data => {
                if (data) {
                    this.setState({
                        show: true,
                        msg: data,
                        apply: data.apply
                    })
                }
            })
        })
    }

    render() {

        const style = {
            textAlign: "left",
            lineHeight: 1.2,
            float: "left",
            margin: "20px"
        };
        const { msg } = this.state;
        return this.state.show ? this.state.apply ?
            <div style={style} dangerouslySetInnerHTML={{ __html: msg.content ? msg.content : '' }}></div> :
            <div style={style}>
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <WhiteSpace size="lg" />
                <h3>您报名的 {msg.labName} 中的 {msg.courseCode + "" + msg.courseName} 研学活动没有通过，请联系管理员了解具体原因{msg.statusCode == 1 ? "之后,修改报名信息重新提交" : ""}</h3>
            </div>
            : null;
    }
}

export default ApplyMsg;