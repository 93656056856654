//export const domain = "http://localhost:9000";
export const domain = window.location.href.search("localhost")!=-1?"http://localhost:9000":"https://yxzc.top/api";

export const loginUrl = {method:"post",url:"/auth/mp/login"}; //登录
export const registerUrl = {method:"post",url:"/auth/mp/register"}; //注册
export const logoutUrl = {method:"get",url:"/manager/logout"}; //退出登录
export const getTelCodeUrl = {method:"get",url:"/manager/send/code/"}; //退出登录{tel}/{type}1登录，2.激活，3重置密码
export const getRegisterCodeUrl = {method:"get",url:"/auth/registCode"};//获取一次性密码

//实验室管理
export const getLabListUrl = {method:"get",url:"/lab/list"}; //实验室列表,分页
export const addLabUrl = {method:"post",url:"/lab/add"}; //添加
export const updateLabUrl = {method:"post",url:"/lab/update"}; //更新
export const updateLabStateUrl  = {method:"post",url:"/lab/update/state"}; //更新
export const getLabDetailUrl = {method:"get",url:"/lab/"}; //{id}
export const getAdminLabUrl = {method:"get",url:"/lab/getAdminLab"}
export const getLockRecordUrl = {method:"get",url:"/lab/lock/record"}

//研学周期
export const getPeriodUrl = {method:"get",url:"/period/"}; //{labId}
export const addPeriodUrl = {method:"post",url:"/period/"}; //{labId}
export const updatePeriodUrl = {method:"put",url:"/period/"}; //{labId}/{status}

export const getLabPartListUrl = {method:"get",url:"/lab/getPart/"}//{labId} //查看某实验室所有分区
export const getPartDetailUrl = {method:"get",url:"/lab/part/"}//{partId} 分区详情
export const addPartUrl = {method:"post",url:"/lab/part/add"}; //添加
export const updatePartUrl = {method:"post",url:"/lab/part/update"}; //更新

//课程管理
export const getLabCourseListUrl = {method:"get",url:"/lab/all/partCourse"}; //实验室课程列表
export const getLabAllCourseListUrl = {method:"get",url:"/course/all/lab/"};//实验室全部课题{labId}
export const getCourseListUrl = {method:"get",url:"/course/list"}; //课程列表
export const addCourseUrl = {method:"post",url:"/course"}; //添加
export const updateCourseUrl = {method:"put",url:"/course"}; //更新
export const deleteCourseUrl = {method:"delete",url:"/course/"}//{id}
export const getCourseDetailUrl = {method:"get",url:"/course/"}; //{id}

//用户
export const getLabAdminListUrl = {method:"get",url:"/lab/select/admin"}//实验室管理员
export const addLabUserUrl = {method:"post",url:"/labUser/add"} //向实验室添加用户
export const updateLabUserUrl = {method:"post",url:"/labUser/update"} //向实验室添加用户
export const getApplyListUrl = {method:"get",url:"/labUser/period/list"} //本研学周期的报名信息
export const getAllApplyListUrl = {method:"get",url:"/labUser/list"} //本研学周期的报名信息
export const getApplyMsgUrl = {method:"get",url:"/labUser/apply/msg/"}//课题申请回复{group}
export const getApplyMsgByLabUrl = {method:"get",url:"/labUser/apply/msg/lab/"}//课题申请信息{labId}
export const updateUserStatusUrl = {method:"post",url:"/labUser/status"} //更改实验室用户状态

//预约
export const getReservePartListUrl = {method:"get",url:"/lab/reserve/partList/"}//{labId} 获取可以预约的分区
export const reserveUrl = {method:"post",url:"/reserve/course/reserve"}//预约
export const getReserveInfoUrl = {method:"get",url:"/reserve/course/reserve/"}//预约信息 {labId}
export const getReserveHistoryUrl = {method:"get",url:"/reserve/course/history/reserve/"}//预约历史信息 {groupId}
export const getEntryLabListUrl =  {method:"get",url:"/lab/entry/lablist"}//可以进入的实验室列表
export const getLabPasswordUrl =  {method:"get",url:"/lab/password/"}//{labId}可以进入的实验室列表

//mp
export const getWxConfigUrl = {method:"post",url:"/mp/js/wxConfig"}; //获取微信配置
export const getMpStatusUrl = {method:"get",url:"/auth/mp/status"}; //获取公众号状态
export const updateMpStatusUrl = {method:"put",url:"/auth/mp/"}; //更新公众号状态 /{status}

//文章
export const getNewsListUrl = {method:"get",url:"/news/list"}

